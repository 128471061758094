<template>
  <el-table
      :data="certTable"
      style="width: 100%">
    <el-table-column
        prop="id"
        label="ID"
       >
    </el-table-column>
    <el-table-column
        prop="certNo"
        label="证书号"
        >
    </el-table-column>
    <el-table-column
        prop="name"
        label="姓名">
    </el-table-column>
    <el-table-column
        prop="idCard"
        label="身份证"
    >
    </el-table-column>
    <el-table-column
        prop="profession"
        label="工种"
    >
    </el-table-column>
    <el-table-column
        prop="employer"
        label="工作单位"
    >
    </el-table-column>
    <el-table-column
        prop="org"
        label="所属机构">

    </el-table-column>
    <el-table-column
        prop="year"
        label="所属年份"
    >

    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: "Index",
  data(){
    return {


    }
  },
  computed:{
    certTable(){
      return this.$store.getters.certTable;
    }
  },
  destroyed() {

  }

}
</script>

<style>

</style>