<template>
  <div>
    <el-row class="text-center">
      <el-col :span="24">
        <div class="grid-content bg-purple-dark">
            <customize-form></customize-form>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin-top: 100px">
      <el-col :span="24">
        <div class="grid-content bg-purple">
          <customize-table></customize-table>
        </div>
      </el-col>

    </el-row>
  </div>
</template>

<script>
import Form from "./form/Index"
import Table from "./table/Index"

export default {
  name: "Index",
  components: {
    customizeForm: Form,
    customizeTable: Table
  },
  data() {

    return {}

  },


}
</script>

<style>


</style>