<template>
  <el-form :model="ruleForm" status-icon  ref="ruleForm"
           label-width="100px"
           class="demo-ruleForm"
           label-position="left"
           >
    <el-form-item label="姓名:" prop="name">
      <el-input type="text" v-model="ruleForm.name" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="年份" prop="year">
      <el-input type="text" v-model="ruleForm.year" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="工种:" prop="typeOfWork">
      <el-select v-model="ruleForm.typeOfWork" clearable placeholder="请选择" style="width: 100%">
        <el-option
            v-for="item in typeOfWorks"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="身份证号:" prop="idCard">
      <el-input v-model="ruleForm.idCard"></el-input>
    </el-form-item>
    <el-form-item label="证书编号:" prop="certNo">
     <el-input v-model="ruleForm.certNo"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import request from "@/util/request";
import qs from 'querystring'
export default {
  name: "Index",
  data() {

    let validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入姓名'));
      } else {
        callback();
      }
    };
    let validateIdCard = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入身份证'));
      } else if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        callback(new Error('身份证格式不正确!'));
      } else {
        callback();
      }
    };
    let validateYear = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入年份'));
      } else if (!/^\d{4}/.test(value)) {
        callback(new Error('年份格式错误!'));
      } else {
        callback();
      }
    };
    let validateCertNo = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入证书号'));
      } else {
        callback();
      }
    };
    let validateTypeOfWork = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请选择工种'));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        name: '',
        idCard: '',
        year: '',
        certNo: '',
        typeOfWork: ""
      },
      area: '',


      typeOfWorks: [{
        label: "建筑起重信号司索工",
        value: 1
      },
        {
          label: "建筑起重机械司机",
          value: 2
        },
        {
          label: "建筑起重机械安装拆卸工",
          value: 3
        }, {
          label: "建筑架子工",
          value: 4
        }

      ]
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          request({
            url: "/person/search.do",
            method: "POST",
            data: this.ruleForm

          }, res => {
            if (res.code === 200) {
              this.$store.dispatch("setCertTable", res.data)
              this.$message({
                message:"查询成功",
                type: "success",
                duration: 1000
              })
            }

          }, err => {
          }, this.$store.getters.token());
        } else {
          return false;
        }
      });
    }
  },
}
</script>

<style >
.demo-ruleForm {
  width: 100%;
}
</style>